import classNames from "classnames";
import { Dispatch, SetStateAction } from "react";
import { SWITCHER_LIST } from "../hook";
import StyledViewSwitcher from "./style";

interface IProps {
  currentViewName: string;
  setCurrentViewName: Dispatch<SetStateAction<string>>;
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
}

const ViewSwitcher: React.FC<IProps> = ({
  currentViewName,
  setCurrentViewName,
  currentDate,
  setCurrentDate,
}) => {
  return (
    <StyledViewSwitcher
      className={classNames(
        currentViewName === "List" ? "customViewSwitcherDayList" : ""
      )}
    >
      <div className="listButton">
        {SWITCHER_LIST.map((item) => (
          <button
            className={classNames(item.id === currentViewName ? "active" : "")}
            key={item.id}
            type="button"
            onClick={() => {
              setCurrentViewName(item.id);
              if (item.id === "Week") {
                setCurrentDate(new Date());
              }
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
      <button
        type="button"
        className={classNames(
          currentViewName === "List" ? "active rightButton" : "rightButton"
        )}
        onClick={() => {
          setCurrentViewName("List");
          if (!currentDate) {
            setCurrentDate(new Date());
          }
        }}
      >
        リスト
      </button>
    </StyledViewSwitcher>
  );
};

export default ViewSwitcher;
