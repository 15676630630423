import moment from 'moment';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';

import calendarIcon from '../../../../assets/icons/calendar.png';
import { DatePickerWrapper } from '../style';

const DateTimePickerField = ({ name, locale = 'ja', formik, placeholder, onChangeEndTime = (endDate) => {} }) => {
    return (
        <DatePickerWrapper>
            <div
                className={classNames(
                    formik.touched[name] && !!formik.errors[name]
                        ? 'error'
                        : '',
                    'innerItem',
                )}
            >
                <img src={calendarIcon} />
                <DatePicker
                    locale={locale}
                    placeholderText={placeholder}
                    dateFormat="yyyy/MM/dd HH:mm aa"
                    timeFormat="HH:mm"
                    showTimeSelect
                    timeIntervals={15}
                    value={
                        formik.values[name]
                            ? new Date(formik.values[name])
                            : null
                    }
                    selected={
                        formik.values[name]
                            ? new Date(formik.values[name])
                            : null
                    }
                    onChange={(date) => {
                        if (!date) {
                            formik.setFieldValue(name, undefined);
                        } else {
                            const value = moment(date).format(
                                'YYYY/MM/DD HH:mm:ss',
                            );

                            formik.setFieldValue(name, new Date(value));
                            formik.setFieldValue("end_date", new Date(value)?.setTime(new Date(value).getTime() + 60*60*1000));
                            onChangeEndTime(value);
                        }
                    }}
                    portalId="html"
                />
            </div>
            {formik.touched[name] && !!formik.errors[name] && (
                <p className="datePickerErrorText">{formik.errors[name]}</p>
            )}
        </DatePickerWrapper>
    );
};

export default DateTimePickerField;
