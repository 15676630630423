import { FC, useState } from 'react';
import classNames from 'classnames';
import {
    Scheduler,
    MonthView,
    WeekView,
    DayView,
    Toolbar,
    DateNavigator,
    Appointments,
    TodayButton,
    AllDayPanel,
    Resources,
    GroupingPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
    ViewState,
    EditingState,
    IntegratedEditing,
    GroupingState,
    IntegratedGrouping,
} from '@devexpress/dx-react-scheduler';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import AppointmentItem from './AppointmentItem';
import DayList from './DayList';
import CreateScheduleDialog from '../CreateScheduleDialog';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import {
    AllDayViewTimeTableCell,
    AllDayViewTimeTableRow,
    DayViewDayScaleCell,
    DayViewTimeTableCell,
    MonthViewDayScaleCell,
    MonthViewTimeTableCell,
} from './Components';
import useSchedulerBox, { Props, ReceivedProps, SWITCHER_LIST } from './hook';
import { SchedulerBoxWrapper } from './style';
import ProjectPlanDialog from '../ProjectPlanDialog';
import { IWorker } from "models";
import { useNavigate } from 'react-router-dom';
import { isAllowAction, isUserGuest } from 'utils/helper/workerHelper';
import WeekTable from './WeekTable';
import DayViewComponent from './DayViewComponent';
import { IWorkersOptions } from 'pages/schedules/FilterBox/hook';
import ViewSwitcher from './ViewSwitcher';

interface IWorkerSchedule {
    id: number | undefined;
    is_agree: boolean | null | string;
    schedule_id: number;
    created_at?: Date | string;
    updated_at: Date | string;
    worker_id: number | undefined;
}
interface IWorkerAttribute {
    id: number;
    is_agree: boolean | null | string;
    worker_id: number;
    name: string;
}
interface INewsSchedule {
    ConferenceId: number[];
    EndTime: Date | string;
    Id: number;
    IsAgree?: boolean | null | string;
    IsAllDay: boolean;
    StartTime: Date | string;
    Subject: string;
    WorkerId: number;
    allDay: boolean;
    color: string;
    content: string;
    endDate: Date | string;
    id: number;
    is_agree: boolean | null | string;
    is_all_day: boolean;
    not_publish: boolean;
    place: string;
    startDate: Date | string;
    title: string;
    worker: IWorker;
    worker_schedule_attributes: IWorkerAttribute[];
    worker_schedules: IWorkerSchedule[];
    created_by: { id: number };
    schedule_maker_is_agree: boolean | null | string;
    NotPublish: boolean;
}

const SchedulerBoxLayout: FC<Props> = ({
    loading,
    currentViewName,
    currentDate,
    localScheduleDate,
    scheduleList,
    openScheduleFormDialog,
    openDeleteConfirmationDialog,
    openPlanUpdateModal,
    workerOptions,
    activeTab,
    deleteSchedule,
    fetchSchedules,
    fetchSchedulesForMonthAndList,
    setCurrentItemId,
    setOpenDeleteConfirmationDialog,
    commitChanges,
    setCurrentDate,
    setCurrentViewName,
    setOpenScheduleFormDialog,
    setOpenPlanUpdateModal,
    dataManager,
    userData,
    searchParam,
    filterWorker,
    isShowDefaultDate,
    setIsShowDefaultDate,
    defaultWorkerRenderOnDayView,
    setDefaultWorkerRenderOnDayView,
}) => {
    const navigate = useNavigate();
    const allow = isAllowAction(userData);
    const [isAllow, setIsAllow] = useState<any>(allow);
    const [defaultDateViewDay, setDefaultDateViewDay] = useState<Date | string>('');
    const [defaultDateViewWeek, setDefaultDateViewWeek] = useState<Date | string>('');
    const [idWorkerClicked, setIdWorkerClicked] = useState<number>(0);
    const [idWorkerClickedViewWeek, setIdWorkerClickedViewWeek] = useState<number>(0);
    const checkUserGuest = isUserGuest(userData);
    interface IWorkerItem {
        id: number;
        name: string;
        departments: number[];
        position: any;
    }

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress size={16} />
            </Box>
        );
    }

    const handleOpenDetail = (data) => {
      setIsShowDefaultDate(false);
      if (data.project_id) {
        setOpenPlanUpdateModal(true);
        navigate(`/sp/calendar`, {
          state: {
            plan_id: data.id,
            project_id: data.project_id,
            currentPlan: data,
          },
        });
      } else {
        if (data.id) {
          if (data.not_publish && userData?.worker?.id !== data?.IDWorkerCheck && userData?.email !== "m-terashima.ken@kyowak.co.jp") {
            setOpenScheduleFormDialog(false);
          } else {
            navigate(`/sp/calendar`, {
              state: {
                editingId: data.id,
              },
            });
            setOpenScheduleFormDialog(true);
          }
  
          if (allow || data?.worker?.id === userData?.worker?.id) {
            setIsAllow(true);
          } else {
            setIsAllow(false);
          }
        }
      }
    }

    const handleOpenFormFromMonthCell = (date) => {
        setDefaultDateViewWeek(date);
        setOpenScheduleFormDialog(true);
      }

    let results: INewsSchedule[] = [];
    let listDataSchedules = dataManager?.dataSource.json as INewsSchedule[];
    
    if (listDataSchedules !== null) {
        for (let i = 0; i < listDataSchedules.length; i++) {
            let listNotRefuse = listDataSchedules[i].worker_schedules?.filter(item => item.is_agree !== false);
            let listIDNotRefuse = listNotRefuse?.map(item => item.worker_id);
            if (listDataSchedules[i]?.schedule_maker_is_agree !== false) {
                listIDNotRefuse?.push(listDataSchedules[i]?.WorkerId);
            }
            let ConferenceIdNotRefuse = listDataSchedules[i].ConferenceId?.filter(item => listIDNotRefuse?.includes(item));
            results?.push({ ...listDataSchedules[i], ConferenceId: ConferenceIdNotRefuse, worker_schedules: listNotRefuse });
        }   
    }

    let listWorkerFilterByDepartment: IWorkerItem[] = [];
    for (let i = 0; i < workerOptions?.length; i++) {
        for (let j = 0; j < workerOptions[i].departments?.length; j++) {
            if (
                searchParam?.attribute_department_ids?.includes(
                    workerOptions[i].departments[j]
                )
            ) {
                listWorkerFilterByDepartment.push(workerOptions[i]);
            }
        }
    }
    // don't show worker who has department 会議室
    let workerOptionCheck = checkUserGuest ? workerOptions?.filter(item => item?.departments2?.filter(dp => dp?.name === "会議室")?.length > 0) : workerOptions;

    const handleClickWorkerFromWeekView = (workerId: number, date: Date) => {
        setDefaultWorkerRenderOnDayView(workerId);
        setCurrentViewName("Day");
        setCurrentDate(date);
    }

    const ascendingWorkerOptions: IWorkersOptions[] = JSON.parse(JSON.stringify(workerOptions));
    ascendingWorkerOptions.sort((a, b) => {
        if (a.identification === null) return -1;
        if (b.identification === null) return 1;
        return a.identification > b.identification ? 1 : -1;
    })

    return (
        <>
            {openScheduleFormDialog && (
                <CreateScheduleDialog
                    fetchSchedules={fetchSchedules}
                    fetchSchedulesForMonthAndList={fetchSchedulesForMonthAndList}
                    open={openScheduleFormDialog}
                    openDeleteConfirmationDialog={openDeleteConfirmationDialog}
                    setOpen={setOpenScheduleFormDialog}
                    setOpenDeleteConfirmationDialog={
                        setOpenDeleteConfirmationDialog
                    }
                    activeTab={activeTab}
                    setCurrentItemId={setCurrentItemId}
                    workerOptions={ascendingWorkerOptions}
                    isAllow={isAllow}
                    scheduleListMeetingRoom={scheduleList?.data?.filter(item => item?.meeting_rooms?.length > 0)}
                    defaultDate={currentViewName === "Day" ? defaultDateViewDay : defaultDateViewWeek}
                    currentViewName={currentViewName}
                    idWorkerClicked={currentViewName === "Day" ? idWorkerClicked : idWorkerClickedViewWeek}
                    isShowDefaultDate={isShowDefaultDate}
                    setIsShowDefaultDate={setIsShowDefaultDate}
                />
            )}

            {openPlanUpdateModal && (
                <ProjectPlanDialog
                    open={openPlanUpdateModal}
                    setOpen={setOpenPlanUpdateModal}
                    fetchSchedules={fetchSchedules}
                />
            )}

            {openDeleteConfirmationDialog && (
                <DeleteConfirmationDialog
                    open={openDeleteConfirmationDialog}
                    setOpen={setOpenDeleteConfirmationDialog}
                    onClick={deleteSchedule}
                />
            )}

            <ViewSwitcher
                currentViewName={currentViewName}
                setCurrentViewName={setCurrentViewName}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
            />

            <SchedulerBoxWrapper
                // className={currentViewName === 'Day' ? 'not-show' : 'show'}
                className={'show'}
            >
                <div
                    className={classNames(
                        'schedulerWrapper',
                        currentViewName === 'List' ? 'hideMainTable' : '',
                        currentViewName === 'Month' ? 'monthMode' : ''
                    )}
                >
                    {currentViewName !== 'Week' &&
                        currentViewName !== 'Day' && (
                            <Scheduler
                                data={localScheduleDate}
                                locale="ja-JP"
                                firstDayOfWeek={
                                    currentViewName === 'Week' ? 1 : 0
                                }
                            >
                                <ViewState
                                    defaultCurrentDate={(() => new Date())()}
                                    currentDate={currentDate}
                                    currentViewName={currentViewName}
                                    onCurrentDateChange={(date) =>
                                        setCurrentDate(date)
                                    }
                                />
                                <EditingState onCommitChanges={commitChanges} />
                                <IntegratedEditing />
                                <MonthView
                                    timeTableCellComponent={
                                        (props) => <MonthViewTimeTableCell onDoubleClick={handleOpenFormFromMonthCell} {...props} />
                                    }
                                    dayScaleCellComponent={
                                        MonthViewDayScaleCell
                                    }
                                />
                                <DayView
                                    timeTableCellComponent={
                                        DayViewTimeTableCell
                                    }
                                    dayScaleCellComponent={DayViewDayScaleCell}
                                />
                                <AllDayPanel
                                    cellComponent={AllDayViewTimeTableCell}
                                    rowComponent={AllDayViewTimeTableRow}
                                    messages={{ allDay: '終日' }}
                                />
                                <Toolbar />
                                {/*<TodayButton messages={{ today: '今日' }} />*/}
                                <div className="btnTodayCustomSP" onClick={() => {setCurrentViewName("Day")}}>今日</div>
                                <DateNavigator />
                                <Appointments
                                    appointmentComponent={({
                                        ...restProps
                                    }) => (
                                        <AppointmentItem
                                            restProps={restProps}
                                            setOpenScheduleFormDialog={
                                                setOpenScheduleFormDialog
                                            }
                                            setOpenPlanUpdateModal={
                                                setOpenPlanUpdateModal
                                            }
                                            currentViewName={currentViewName}
                                            setCurrentViewName={
                                                setCurrentViewName
                                            }
                                            setCurrentDate={setCurrentDate}
                                            setIsShowDefaultDate={setIsShowDefaultDate}
                                        />
                                    )}
                                />
                            </Scheduler>
                        )}

                    {currentViewName === 'Week' && (
                      <WeekTable 
                        currentDate={currentDate}
                        workerData={
                          (activeTab === 0
                            ? workerOptionCheck?.filter(
                                  (item) => item.id === userData?.worker?.id
                              )
                            : searchParam?.worker_list?.length >0
                            ? workerOptionCheck?.filter((v) =>
                                  searchParam?.worker_list.includes(v.id)
                              )
                            : workerOptionCheck
                          ).map((v) => ({
                              text: v.name,
                              id: v.id,
                          })) || []
                        }
                        scheduleData={listDataSchedules}
                        setCurrentViewName={setCurrentViewName}
                        setCurrentDate={setCurrentDate}
                        onOpenDetail={handleOpenDetail}
                        handleChangeView={(selectedDate) => {
                          setCurrentViewName("Day");
                          setCurrentDate(selectedDate);
                        }}
                        setDefaultDate={setDefaultDateViewWeek}
                        setOpenScheduleFormDialog={setOpenScheduleFormDialog}
                        setIdWorkerClicked={setIdWorkerClickedViewWeek}
                        handleClickWorkerFromWeekView={handleClickWorkerFromWeekView}
                      />
                    )}

                    {currentViewName === "List" && (
                        <DayList
                            data={scheduleList.data}
                            currentDate={currentDate}
                            setOpenScheduleFormDialog={
                                setOpenScheduleFormDialog
                            }
                        />
                    )}
                </div>
            </SchedulerBoxWrapper>

            {
                currentViewName === "Day" && (
                    <DayViewComponent 
                        setIdWorkerClicked={setIdWorkerClicked}
                        setDefaultDate = {setDefaultDateViewDay}
                        currentViewName={currentViewName}
                        currentDate={currentDate}
                        dataSource={results}
                        setOpenScheduleFormDialog={setOpenScheduleFormDialog}
                        setCurrentDate={setCurrentDate}
                        workerGroup={
                          (activeTab === 0
                            ? workerOptionCheck?.filter(
                                  (item) =>
                                      item.id ===
                                      (userData as any)?.worker
                                          ?.id
                              )
                            : searchParam?.worker_list?.length >
                              0
                            ? workerOptionCheck?.filter((v) =>
                                  searchParam?.worker_list.includes(
                                      v.id
                                  )
                              )
                            : workerOptionCheck
                          ).map((v) => ({
                              text: v.name,
                              id: v.id,
                          })) || []
                        }
                        defaultWorkerRenderOnDayView={defaultWorkerRenderOnDayView}
                    />
                )
            }
        </>
    );
};

const SchedulerBox: FC<ReceivedProps> = (props) => (
    <SchedulerBoxLayout {...useSchedulerBox(props)} />
);

export default SchedulerBox;
