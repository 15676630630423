import { Box, Typography, CardMedia } from '@mui/material';
import { getUser } from '../../store/user/user';
import { useAppDispatch } from '../../store/index';
import { useEffect } from 'react';
interface ISPHeader {
    isOpenMenu: boolean;
    setIsOpenMenu: (isOpenMenu: boolean) => void;
}
const SPHeader = ({ setIsOpenMenu, isOpenMenu }: ISPHeader) => {
    const dispatch = useAppDispatch();

    const getUserProfile = async () => {
        try {
            dispatch(getUser());
        } catch (error) {}
    };

    useEffect(() => {
        getUserProfile();
    }, []);
    if (window?.location?.pathname == '/login') return <></>;
    return (
        <Box
            bgcolor="#215493"
            height={44}
            display="flex"
            alignItems="center"
            position="relative"
            justifyContent="center"
            padding="10px 16px"
            sx={{
                '@media only screen and (min-width: 992px)': {
                    display: 'none',
                },
            }}
        >
            <CardMedia
                component="img"
                onClick={() => setIsOpenMenu(true)}
                sx={{
                    width: '24px',
                    height: '24px',
                    position: 'absolute',
                    left: '0px',
                    ml: '16px',
                    cursor: 'pointer',
                }}
                src={process.env.PUBLIC_URL + '/img/hambuger_ic.svg'}
                alt="open_menu_icon"
            />
            <Typography
                fontWeight={500}
                fontFamily="Zen Kaku Gothic Antique"
                color="#fff"
            >
                協和建材システム
            </Typography>
        </Box>
    );
};
export default SPHeader;
