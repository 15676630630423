import styled from "styled-components";

const StyledSPDeleteUserWrapper = styled.div`
  width: 399px;
  height: 152px;
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .delete-popup-title {
    width: 100%;
    font-family: "Zen Kaku Gothic Antique";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #1c1e21;
  }

  .delete-popup-button-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .return-button {
      width: 49%;
      height: 44px;
      border-radius: 8px;
      outline: none;
      border: 1px solid #215493;
      color: #215493;
    }

    .register-button {
      width: 49%;
      height: 44px;
      background: #215493;
      border-radius: 8px;
      color: #ffffff;
      outline: none;
      border: none;
    }
  }
`;

export default StyledSPDeleteUserWrapper;
