import React, { FC, useEffect, useState } from "react";
import Scheduler, { Resource, View } from "devextreme-react/scheduler";
import "devextreme/dist/css/dx.light.css";
import moment from "moment";
import useDayViewComponent, { Props, ReceivedProps } from "./hook";
import Styles from "./styled";
import { useNavigate } from "react-router-dom";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from "@mui/icons-material";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { SLASH_LINES_BACKGROUND } from "../WeekTable";
import { ContentReadyEvent } from "devextreme/ui/scheduler";

const groups = ["ConferenceId"];

const StyledButton = styled(Button)({
  color: "rgb(28, 30, 33)",
  fontWeight: 500,
  fontSize: "14px",
  minWidth: "58px",
  background: "rgb(231, 231, 231)",
  border: "1px solid rgba(28, 30, 33, 0.2)",
  cursor: "pointer",
  borderRadius: "4px",
  textAlign: "center",
  padding: "0px",
  marginLeft: "25px",
});

export const findParticipantWithMostSchedules = (schedules: any[]) => {
  const participantScheduleCount: Record<number, number> = {};

  // Counter the number of schedules for each participant
  schedules.forEach(schedule => {
    schedule.ConferenceId.forEach(id => {
      if (!participantScheduleCount[id]) {
        participantScheduleCount[id] = 0;
      }
      participantScheduleCount[id]++;
    });
  });

  let maxSchedules = 0;
  let participantWithMostSchedules: number | null = null;

  // Find the participant with the most schedules
  Object.entries(participantScheduleCount).forEach(([participant, count]) => {
    const participantId = parseInt(participant);
    if (count > maxSchedules) {
      maxSchedules = count;
      participantWithMostSchedules = participantId;
    }
  });

  return {
    participantWithMostSchedules,
    maxSchedules,
    participantScheduleCount
  }
}


const DayViewComponentLayout: FC<Props> = ({
  currentDate,
  currentViewName,
  dataSource,
  setOpenScheduleFormDialog,
  workerGroup,
  setCurrentDate,
  setDefaultDate,
  setIdWorkerClicked,
  defaultWorkerRenderOnDayView,
  handleClickWorkerFromDayView,
}) => {
  const { userData } = useSelector((state: RootState) => state.users);
  const navigate = useNavigate();
  const [viewTimeHour, setViewTimeHour] = useState(12);
  const getDayByCurrentDay = dataSource.filter((item) =>
    moment(moment(item?.startDate).format("YYYY/MM/DD")).isSame(
      moment(currentDate).format("YYYY/MM/DD"),
    ),
  );
  
  const newDataSource = JSON.parse(JSON.stringify(dataSource)).filter((item) => item.ConferenceId && item.ConferenceId.length > 0).map((item: any) => {
    return {
      ...item,
      startDate: new Date(item.startDate),
      endDate: new Date(item.endDate),
      workerId: item.WorkerId,
    }
  })

  const moveToYesterday = () => {
    setCurrentDate((today) => moment(today).subtract(1, "days"));
  };

  const moveToTomorrow = () => {
    setCurrentDate((today) => moment(today).add(1, "days"));
  };

  const findData = findParticipantWithMostSchedules(newDataSource);

  const handleContentReady = (e: ContentReadyEvent) => {
    const scheduler = e.component;
    const currentDate = scheduler.getStartViewDate();
    currentDate.setHours(viewTimeHour, 0, 0);
    if (defaultWorkerRenderOnDayView) {
      scheduler.scrollTo(currentDate, { ConferenceId: defaultWorkerRenderOnDayView });
    } else {
      scheduler.scrollTo(currentDate);
    }
  }

  const resourcesData = [...workerGroup.map((item) => ({
    id: item.id,
    text: item.text,
    identification: item.identification,
  }))];

  return (
    <Styles className={currentViewName}>
      <div className="dateNavigate relative flex w-[55%] justify-between mb-5">
        <div className="ml-4">
          <button onClick={moveToYesterday}>
            <ArrowBackIosOutlined style={{ color: "#0000008a" }} />
          </button>
          <button onClick={moveToTomorrow} className="ml-3">
            <ArrowForwardIosOutlined style={{ color: "#0000008a" }} />
          </button>
          <StyledButton onClick={() => setCurrentDate(new Date())}>今日</StyledButton>
        </div>

        <div className="font-semibold">{moment(currentDate).format("YYYY年MM月DD日")}</div>
      </div>
      <Scheduler
        adaptivityEnabled={true}
        // timeZone="UTC+7"
        dataSource={newDataSource}
        views={["timelineDay"]}
        crossScrollingEnabled={true}
        defaultCurrentView="timelineDay"
        defaultCurrentDate={new Date(moment(currentDate).toISOString())}
        groups={groups}
        maxAppointmentsPerCell="unlimited"
        showCurrentTimeIndicator={true}
        cellDuration={15}
        startDayHour={0}
        onContentReady={handleContentReady}
        height={"500px"}
        endDayHour={24}
        editing={false}
        timeCellComponent={(props: any) => {
          if (
            props.data.text?.includes("15") ||
            props.data.text?.includes("45")
          ) {
            const eles = document.getElementsByClassName("dx-scheduler-header-panel-cell");
            Array.from(eles)?.map((ele, index: number) => {
              if (index % 2 !== 0) {
                ele.classList.value += " hiddenTime"
              }
            })
            return <div style={{ display: "none" }}></div>
          }
          return <div {...props}>{moment(props.data.date).format("H:mm")}</div>;
        }}
        focusStateEnabled={false}
        onAppointmentDblClick={(e) => {
          e.cancel = true;
        }}
        appointmentDragging={{
          onDragStart: (e) => {
            e.cancel = true;
          },
        }}
        onAppointmentTooltipShowing={(e) => {
          e.cancel = true;
        }}
        dataCellRender={(props) => {
          let style: any = {
            height: findData.maxSchedules > 2 ? `${findData.maxSchedules * 50}px` : "150px",
          };
          return (
            <div style={style}></div>
          )
        }}
        onCellClick={(e) => {
          const { startDate } = e.cellData;
          setOpenScheduleFormDialog(true);
          navigate(`/calendar`, {
            state: {
              editingId: null,
            },
          });
          setDefaultDate(startDate);
          setIdWorkerClicked(e.cellData?.groups?.ConferenceId || 0);
          setViewTimeHour(new Date(startDate).getHours())
        }}
        resourceCellComponent={(props) => {
          const targetId = props.index === (resourcesData.length - 1) ? props.data.id : resourcesData[props.index + 1].id;
          return <div className="dx-scheduler-group-header-content cursor-pointer" onClick={() => handleClickWorkerFromDayView(targetId)}>
              {props.data.text}
          </div>
        }}

        appointmentComponent={(props: any) => {
          // check to show slash lines
          let isShowSlashLines = false;
          let assignWorker = props?.data?.appointmentData?.worker_schedules?.filter(item => item?.worker_id === props.data?.targetedAppointmentData?.ConferenceId);
          if (props.data?.targetedAppointmentData?.ConferenceId === props?.data?.appointmentData?.worker?.id && props?.data?.appointmentData?.schedule_maker_is_agree === null) {
            isShowSlashLines = true;
          } else if (assignWorker[0] && assignWorker[0].is_agree === null && assignWorker[0].worker_id !== props?.data?.appointmentData?.created_by?.id) {
            isShowSlashLines = true;
          }
           //check flow of acc m-terashima.ken@kyowak.co.jp not_publish
           let isNotPublish = props?.data?.appointmentData?.not_publish && userData.worker?.id !== props.data?.targetedAppointmentData?.ConferenceId && userData?.email !== "m-terashima.ken@kyowak.co.jp";
          return (
            <div
              onClick={() => {
                if (props?.data?.appointmentData?.project_id) {
                  navigate(
                    `/projects/detail/${props?.data?.appointmentData?.project_id}/plan`,
                    {
                      state: {
                        id: props?.data?.appointmentData?.project_id,
                      },
                    },
                  );
                } else {
                  if (isNotPublish) {
                    setOpenScheduleFormDialog(false);
                  } else {
                    navigate(`/calendar`, {
                      state: {
                        editingId: props?.data?.appointmentData?.id,
                      },
                    });
                    setOpenScheduleFormDialog(true);
                  }
                }
              }}
              {...props}
              style={{
                height: "100%",
                background: isShowSlashLines ? SLASH_LINES_BACKGROUND : '',
                backgroundColor:
                  props?.data?.appointmentData?.color || "rgb(245,245,245)",
                color: props?.data?.appointmentData?.color
                  ? "#ffffff"
                  : "#000000",
                padding: 10,
              }}
            >
              {
                props.data.appointmentData.duration !== null &&
                props.data.appointmentData.duration >= 30 && (
                  <>
                    <p className="truncate">
                      {isNotPublish ? "アポ有り" : (props.data?.appointmentData?.title ||
                        props.data?.appointmentData?.content ||
                        props.data?.appointmentData?.name)}
                    </p>
                    {!isNotPublish && <>
                      {props.data?.appointmentData?.allDay ? (
                        <p>終日</p>
                      ) : (
                        <p className="break-all whitespace-normal">
                          {props.data?.appointmentData?.title
                            ? moment(props.data?.appointmentData?.startDate).format(
                              "HH:mm",
                            ) + " - "
                            : ""}
                          {props.data?.appointmentData?.title
                            ? moment(props.data?.appointmentData?.endDate).format(
                              "HH:mm",
                            )
                            : ""}
                        </p>
                      )}
                    </>}
                  </>
                )
              }
            </div>
          );
        }}
      >
        <View
            name="Vertical Grouping"
            type="timelineDay"
            groupOrientation="vertical"
        />
        <View
            name="Horizontal Grouping"
            type="day"
            groupOrientation="horizontal"
        />
        <Resource
          fieldExpr="ConferenceId"
          allowMultiple={true}
          dataSource={resourcesData}
          label="Worker"
        />
      </Scheduler>
    </Styles>
  );
};

const DayViewComponent: FC<ReceivedProps> = (props) => (
  <DayViewComponentLayout {...useDayViewComponent(props)} />
);

export default DayViewComponent;
