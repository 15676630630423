import { FC } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

import useFilterBox, { Props, ReceivedProps } from './hook';
import FilterBoxWrapper from './style';
import { workerIdentSortTypes } from 'utils/constants';

const FilterBoxLayout: FC<Props> = ({
    formik,
    workerOptions,
    positionOptions,
    departmentOptions,
    listBookMark,
    deleteBookMark,
    createBookMark,
    bookMarkDetail,
    filterBookMarks,
    identSort,
    setIdentSort,
}) => {
    return (
        <FilterBoxWrapper>
            <form onSubmit={formik.handleSubmit} className="filterBox relative">
                <div className="filterBoxBottom">
                    <div className="filterBoxCol">
                        <span className="fieldLabel">部署</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                multiple
                                value={formik.values.attribute_department_ids}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'attribute_department_ids',
                                        newValue
                                    );
                                }}
                                noOptionsText="該当なし"
                                options={departmentOptions || []}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol">
                        <span className="fieldLabel">役職</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                multiple
                                value={formik.values.attribute_position_ids}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'attribute_position_ids',
                                        newValue
                                    );
                                }}
                                noOptionsText="該当なし"
                                options={positionOptions || []}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol">
                        <span className="fieldLabel">並び替え</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                // value={formik.values.worker_identification}
                                // onChange={(event, newValue) => {
                                //     formik.setFieldValue(
                                //         'worker_identification',
                                //         newValue
                                //     );
                                // }}
                                value={identSort}
                                onChange={(_, newValue) => {
                                    setIdentSort(newValue);
                                }}
                                noOptionsText="該当なし"
                                options={workerIdentSortTypes}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol">
                        <span className="fieldLabel">社員</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                multiple
                                value={formik.values.attribute_worker_ids}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'attribute_worker_ids',
                                        newValue
                                    );
                                }}
                                noOptionsText="該当なし"
                                options={workerOptions || []}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol">
                        <span className="fieldLabel">キーワード</span>
                        <div className="wrapTextField haveIcon">
                            <TextField
                                fullWidth
                                hiddenLabel
                                name="free_word"
                                type="text"
                                variant="outlined"
                                margin="dense"
                                onChange={formik.handleChange}
                                value={formik.values.free_word}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol !w-fit">
                        <button
                            type="submit"
                            className="modal-create-plan-button2 d-flex flex-row"
                            style={{
                                width: 100,
                                marginLeft: 0,
                                marginTop: 7,
                                height: 32,
                            }}
                        >
                            検索
                        </button>
                    </div>
                    <div className="filterBoxCol">
                        <div className="wrapTextField haveIcon mt-[7px]">
                            <TextField
                                fullWidth
                                hiddenLabel
                                name="name"
                                type="text"
                                variant="outlined"
                                margin="dense"
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'name',
                                        event.target.value
                                    );
                                }}
                                value={formik.values.name}
                            />
                        </div>
                    </div>
                    <div className="filterBoxCol">
                        <button
                            type="button"
                            disabled={
                                formik.values.name &&
                                listBookMark &&
                                listBookMark.length < 10
                                    ? false
                                    : true
                            }
                            onClick={createBookMark}
                            className="modal-create-plan-button2 d-flex flex-row btnSave"
                            style={{
                                width: 120,
                                marginLeft: 0,
                                marginTop: 7,
                                height: 32,
                            }}
                        >
                            お気に入り
                        </button>
                    </div>
                </div>
                <div className="flex gap-x-[8px] absolute">
                    {listBookMark?.map((item, index) => (
                        <div key={index} className="bookmark">
                            <p onClick={() => filterBookMarks(item.id)}>
                                {item?.name}
                            </p>
                            <CloseIcon
                                onClick={() => deleteBookMark(item.id)}
                                className="icon-del"
                            />
                        </div>
                    ))}
                </div>
            </form>
        </FilterBoxWrapper>
    );
};

const FilterBox: FC<ReceivedProps> = (props) => (
    <FilterBoxLayout {...useFilterBox(props)} />
);

export default FilterBox;
