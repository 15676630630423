import styled from "styled-components";

const StyledViewSwitcher = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #215493;
    border: 1px solid rgba(33, 84, 147, 0.4);
    transition: all 0.3s ease 0s;
    border-radius: 6px;
    background: none;
    padding: 4px 22px;

    &.active,
    &:hover {
      color: #ffffff;
      background: #215493;
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    }
  }
  .listButton {
    margin-left: 14px;
    display: flex;
    gap: 10px;
  }
  .rightButton {
    margin-right: 15px;
  }

  &.customViewSwitcherDayList {
    position: initial;
    margin-bottom: 12px;
    .listButton {
      margin-left: 0px;
    }
  }
`;

export default StyledViewSwitcher;
