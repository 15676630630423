import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import scheduleService from 'services/scheduleService';
import { CustomizedState } from '../SchedulerBox/hook';
import { validate } from './validate';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IWorkersOptions } from 'pages/schedules/FilterBox/hook';
import { fromZonedTime } from 'date-fns-tz';
import { TOKYO_TZ } from 'utils/constants';
import { alertError } from 'utils/helper/appHelper';

export type ReceivedProps = {
    open: boolean;
    openDeleteConfirmationDialog: boolean;
    workerOptions: {
        id: any;
        name: string;
    }[];
    activeTab: number;
    fetchSchedules: () => Promise<void>;
    fetchSchedulesForMonthAndList: () => Promise<void>;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteConfirmationDialog: Dispatch<SetStateAction<boolean>>;
    setCurrentItemId: Dispatch<SetStateAction<number>>;
    isAllow: boolean;
    defaultDate: Date | string;
    currentViewName: string;
    idWorkerClicked: number;
    isShowDefaultDate: boolean;
    setIsShowDefaultDate: Dispatch<SetStateAction<boolean>>;
    scheduleListMeetingRoom 
};

export const SCHEDULER_COLORS = [
    '#195192',
    '#4950F1',
    '#D83232',
    '#E3A900',
    '#002A5B',
    '#FF6231',
    '#32CED8',
    '#009F08',
    '#754D4D',
    '#FE3571',
    '#B7B7B7',
];

const useCreateScheduleDialog = (props: ReceivedProps) => {
    const location = useLocation();
    const state = location.state as CustomizedState;
    const editingId = state?.editingId;
    const { userData } = useSelector((state: RootState) => state.users);
    const [isScheduleMaker, setScheduleMaker] = useState<boolean>(false);
    const [isErrorMeetingRoom, setIsErrorMettingRoom] = useState(false);
    const [errorTimeMessage, setErrorTimeMessage] = useState("");
    const isAllowShowNotPublic = () => {
        if (userData?.email === "m-terashima.ken@kyowak.co.jp") return true;
    }
    const initialStartDate = props.isShowDefaultDate 
        ? (props.currentViewName === "Day" ? props.defaultDate : moment(props.defaultDate).set({ hour: 8 , minute: 0, second: 0 }).format()) 
        : moment().set({ hour: 8 , minute: 0, second: 0 }).format();
    const initialEndDate = props.isShowDefaultDate 
        ? (props.currentViewName === "Day" ? new Date(props.defaultDate)?.setTime(new Date(props.defaultDate).getTime() + 60*60*1000) : moment(props.defaultDate).set({ hour: 9, minute: 0, second: 0 }).format())
        : moment().set({ hour: 9 , minute: 0, second: 0 }).format();

    const isExecutive = () => {
        let check = false;
        if (userData?.email === "admin@kyowa.javis.vn") check = true;
        userData?.worker?.departments && userData?.worker?.departments?.length > 0 && userData?.worker?.departments.map((department) => {
            if (department.name === "役員") check = true
        })
        return check
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: (() => ({
            worker_id:
                props.activeTab === 0
                    ? props.workerOptions?.find(
                        (item) => item.id === (userData as any)?.worker?.id,
                    )
                    : props.isShowDefaultDate ? (props.workerOptions?.filter(worker => worker.id === props.idWorkerClicked)[0] || null) : null,
            title: '',
            start_date: initialStartDate,
            end_date: initialEndDate,
            is_all_day: false,
            worker_schedules_attributes: [],
            color: '#195192',
            content: '',
            place: '',
            not_publish: false,
            created_by: {id: 0},
            worker: {id: 0}
        }))(),
        validate,
        onSubmit: (values) => submitForm(values),
    });

    const fetchSchedule = useCallback(async () => {
        try {
            const { schedule } = await scheduleService.getScheduleSP(editingId);
            if (userData?.worker?.id === schedule?.worker?.id) setScheduleMaker(true)
            else setScheduleMaker(false)
            const worker_schedules_attributes = [
                ...schedule.worker_schedule_attributes,
            ].map((item) => ({
                ...item,
                id: item.worker_id,
            }));

            formik.setValues({
                ...schedule,
                worker_id: props.workerOptions?.find(
                    (item) => item.id === schedule?.worker?.id,
                ),
                worker_schedules_attributes,
                start_date: moment(schedule.start_date).zone('+0900').format('YYYY-MM-DD HH:mm'),
                end_date: moment(schedule.end_date).zone('+0900').format('YYYY-MM-DD HH:mm'),
            });
        } catch (error) {
            //
        }
    }, [editingId]);

    const submitForm = async (payload: Record<string, any>) => {
        const data: any = {
            ...payload,
            worker_id: payload.worker_id.id,
        };

        const arrMeetingRooms: number[] = [];
        if(payload.worker_id?.departments2?.filter(item => item?.name === "会議室")?.length > 0) {
            arrMeetingRooms.push(payload.worker_id.id)
        }
        if(formik.values.worker_schedules_attributes?.length > 0) {
            formik.values.worker_schedules_attributes?.map((item: IWorkersOptions) => {
                if(!item.departments2) return;
                if(item?.departments2?.filter(item => item?.name === "会議室")?.length > 0) {
                    arrMeetingRooms.push(item.id)
                }
            })
        }
        data.meeting_rooms = arrMeetingRooms;

        if (data.is_all_day) {
            const start_date = moment(data.start_date).format('YYYY/MM/DD');
            const end_date = moment(data.end_date).format('YYYY/MM/DD');

            data.start_date = data.start_date ? moment(new Date(`${start_date}`)).zone('+0900').set({ hour: 0, minute: 0, second: 0}).toISOString(true) : null;
            data.end_date = data.end_date ? moment(new Date(`${end_date}`)).zone('+0900').set({ hour: 23, minute: 59, second: 59}).toISOString(true) : null;
        } else {
            let convertedStartDate: Date | string = fromZonedTime(data.start_date, TOKYO_TZ);
            let convertedEndDate: Date | string = fromZonedTime(data.end_date, TOKYO_TZ);
            convertedStartDate = moment(convertedStartDate).zone('+0900').toISOString(true);
            convertedEndDate = moment(convertedEndDate).zone('+0900').toISOString(true);

            data.start_date = convertedStartDate;
            data.end_date = convertedEndDate;
        }

        // check time when create meeting room
        let timeRanges: { start: Date, end: Date }[] = [];
        
        let arrExitsMeetingRoom = props.scheduleListMeetingRoom?.filter(item => item?.meeting_rooms?.some(r => data.meeting_rooms?.includes(r)))
        let isExistMeetingRoom = arrExitsMeetingRoom?.length > 0
        for (let i = 0; i < arrExitsMeetingRoom?.length; i++) {
            timeRanges?.push({ start: new Date(arrExitsMeetingRoom[i]?.startDate), end: new Date(arrExitsMeetingRoom[i]?.endDate) })

        }
        let isSameTime = timeRanges?.some(range => new Date(data.start_date) >= range?.start && new Date(data.start_date) <= range?.end)

        const { worker_schedule_attributes } = data;
        const newScheduleAttr = formik.values.worker_schedules_attributes.map(
            (workerItem: any) => ({
                worker_id: workerItem.id,
                existingOnForm: true,
            }),
        );
        // validate start_time, end_time
        let errorTimer = false;
        if (!data.start_date || !data.end_date) {
            errorTimer = true;
            setErrorTimeMessage('日付は必須です');
        } else {
            if (new Date(data.start_date).getTime() > new Date(data.end_date).getTime()) {
                errorTimer = true;
                setErrorTimeMessage('終了は開始日時以降を入力してください。');
            } else if(new Date(data.start_date).getTime() === new Date(data.end_date).getTime()) {
                errorTimer = true;
                setErrorTimeMessage('終了時刻は開始時刻以降より入力してください');
            } else {
                errorTimer = false;
                setErrorTimeMessage("");
            }
        }
        const mergeArray = _.merge(
            _.keyBy(worker_schedule_attributes, 'worker_id'),
            _.keyBy(newScheduleAttr, 'worker_id'),
        );
        const newArray = _.values(mergeArray);

        data.worker_schedules_attributes = newArray.map((item) => {
            if (item.existingOnForm && !item.id) {
                return {
                    worker_id: item.worker_id,
                };
            }
            if (!item.existingOnForm && item.id) {
                return {
                    _destroy: true,
                    id: item.id.toString(),
                    worker_id: item.worker_id,
                };
            }
            return {
                id: item.id.toString(),
                worker_id: item.worker_id,
            };
        });

        delete data.worker_schedule_attributes;

        try {
            if (errorTimer) {
                setTimeout(() => {
                    setErrorTimeMessage("");
                }, 1500)
                return;
            } else {
                if (!editingId) {
                    if (isExistMeetingRoom && isSameTime) {
                        alertError("※同時間帯で既に予約が入っています");
                        props.setOpen(false);
                        return;
                    }
                    await scheduleService.createScheduleSP(data);
                    props.setOpen(false);
                } else {
                    await scheduleService.updateScheduleSP(editingId, data);
                    props.setOpen(false);
                }
            }
            if (props.currentViewName === "Month" || props.currentViewName === "List") {
              props.fetchSchedulesForMonthAndList();
            } else {
              props.fetchSchedules();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error;
            alertError(errorMessage);
            props.setOpen(false);
        } finally {
        //   props.setOpen(false);
        }
    };

    useEffect(() => {
        fetchSchedule();
    }, [fetchSchedule]);

    return {
        ...props,
        formik,
        editingId,
        isAllowShowNotPublic,
        isExecutive,
        isScheduleMaker,
        isErrorMeetingRoom,
        setIsErrorMettingRoom,
        errorTimeMessage,
        setErrorTimeMessage
    };
};

export type Props = ReturnType<typeof useCreateScheduleDialog>;

export default useCreateScheduleDialog;
