import { Dispatch, FC, SetStateAction } from "react";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#1c1e21",
    boxShadow: theme.shadows[1],
    fontSize: 10,
  },
}));

type IProps = {
  restProps: any;
  currentViewName: string;
  setOpenScheduleFormDialog: Dispatch<SetStateAction<boolean>>;
  setOpenPlanUpdateModal: Dispatch<SetStateAction<boolean>>;
  setCurrentViewName: Dispatch<SetStateAction<string>>;
  setCurrentDate: Dispatch<SetStateAction<any>>;
  setIsShowDefaultDate: Dispatch<SetStateAction<boolean>>;
};

const AppointmentItem: FC<IProps> = ({
  restProps,
  currentViewName,
  setOpenScheduleFormDialog,
  setOpenPlanUpdateModal,
  setCurrentViewName,
  setCurrentDate,
  setIsShowDefaultDate,
}) => {
  const { children } = restProps;
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.users);

  let idWorkerScheduleAttributes = restProps?.data?.worker_schedule_attributes?.map(item => item.worker_id)
  let isException = userData?.worker?.id === restProps.data.worker.id || idWorkerScheduleAttributes?.includes(userData?.worker?.id);

  let findWorker = restProps?.data?.worker_schedules?.filter((item: { worker_id: number; }) => item?.worker_id === userData?.worker?.id);
  let isScheduleMarkerShowSlashLine = restProps?.data?.worker?.id === userData?.worker?.id && restProps?.data?.worker?.id !== restProps?.data?.created_by?.id;
  // Check show slash lines when is_agrre null and assign worker is not creater
  let isShowSlashLines = (findWorker[0]?.is_agree === null && findWorker[0]?.worker_id !== restProps?.data?.created_by?.id) || (isScheduleMarkerShowSlashLine && restProps?.data?.schedule_maker_is_agree === null);

  // Check if refuse(is_agree: false) => hidden schedule
  let workerAttributeAndWorker = restProps?.data?.worker_schedules
  if(restProps?.data?.schedule_maker_is_agree !== null) {
    workerAttributeAndWorker.push({
      id: 0,
      worker_id: restProps?.data?.worker.id,
      schedule_id: restProps?.data?.id,
      created_at: "2023-10-02T22:31:19.520+09:00",
      updated_at: "2023-10-02T22:31:19.520+09:00",
      is_agree: restProps?.data?.schedule_maker_is_agree
    })
  }
  let workerRefuseSchedule = workerAttributeAndWorker?.filter(item => item?.is_agree === false && item?.worker_id === userData?.worker?.id);
 
  if (currentViewName === "Month") {
    if (workerRefuseSchedule?.length > 0) return <></>;
    return (
      <Appointments.Appointment
        {...restProps}
        appointmentType=""
        onClick={() => {
          setIsShowDefaultDate(false);
          if (restProps.data.project_id) {
            setOpenPlanUpdateModal(true);
            navigate(`/calendar`, {
              state: {
                plan_id: restProps.data.id,
                project_id: restProps.data.project_id,
                currentPlan: restProps.data,
              },
            });
          } else {
            if (restProps.data.showWeekView) {
              setCurrentViewName("Week");
              setCurrentDate(new Date(restProps.data.startDate));
            } else {
              navigate(`/calendar`, {
                state: {
                  editingId: restProps.data.id,
                },
              });
              restProps?.data?.not_publish && !isException ? setOpenScheduleFormDialog(false) : setOpenScheduleFormDialog(true);
            }
          }
        }}
        style={(() => {
          if (restProps.data.showWeekView) {
            return {
              backgroundColor: "#195192",
            };
          }
          if (isShowSlashLines) {
            return {
              background: `repeating-linear-gradient(
                -45deg,
                transparent,
                transparent 4px,
                transparent 1px,
                gray 7px
              ),
              linear-gradient(
                to bottom,
                transparent,
                transparent
              )`,
              backgroundColor: restProps.data.color || "#195192",
            }
          }
          return {
            backgroundColor: restProps.data.color || "#195192",
          };
        })()}
      >
        {(restProps?.data?.not_publish && !isException) ? <span className="!text-white">アポ有り</span> : children}
      </Appointments.Appointment>
    );
  }

  return (
    <LightTooltip
      title={
        <button
          type="button"
          onClick={() => {
            if (restProps.data.project_id) {
              navigate(`/projects/detail/${restProps.data.project_id}/plan`, {
                state: { id: restProps.data.project_id },
              });
            } else {
              navigate(`/calendar`, {
                state: {
                  editingId: restProps.data.id,
                },
              });
              setOpenScheduleFormDialog(true);
            }
          }}
        >
          {restProps.data.title}
        </button>
      }
      arrow
      placement="top-end"
      followCursor
    >
      <div className="wrapTooltip">
        <Appointments.Appointment
          {...restProps}
          onClick={() => {
            if (restProps.data.project_id) {
              navigate(`/projects/detail/${restProps.data.project_id}/plan`, {
                state: { id: restProps.data.project_id },
              });
            } else {
              navigate(`/calendar`, {
                state: {
                  editingId: restProps.data.id,
                },
              });
              setOpenScheduleFormDialog(true);
            }
          }}
          style={{
            background: restProps.data.color || "#195192",
          }}
        >
          {children}
        </Appointments.Appointment>
      </div>
    </LightTooltip>
  );
};

export default AppointmentItem;
