import {
  Dispatch,
  SetStateAction,
} from "react";
export type ReceivedProps = {
  currentDate: any;
  dataSource: any;
  currentViewName: string;
  setOpenScheduleFormDialog: any;
  workerGroup: any[];
  setCurrentDate: any;
  setDefaultDate: Dispatch<SetStateAction<Date | string>>;
  setIdWorkerClicked: Dispatch<SetStateAction<number>>;
  defaultWorkerRenderOnDayView: number | null;
  handleClickWorkerFromDayView: (workerId: number) => void;
};

const useWeekView = (props: ReceivedProps) => {
  return {
    ...props,
  };
};

export type Props = ReturnType<typeof useWeekView>;

export default useWeekView;
