import "./App.css";
import SideBar from "./components/SideBar";
import Header from "./components/Header";

import { useSelector } from "react-redux";
import Login from "./pages/login/Login";

import Dashboard from "./pages/dashboard";
import DashboardSP from "./pages/dashboard/dashboardSP";
import ProjectCreate from "./pages/project/projectCreate";
import Projects from "./pages/project/index";
import ProjectsSP from "./pages/project/projectsSP";
import ChatsSP from "./pages/spchats/ChatsList";
import ChatControlSP from "./pages/spchats/ChatControl";
import ProjectUpdate from "./pages/project/projectUpdate";
import ChatBoxSP from "./pages/spchats/ChatBox";

import WorkingReport from "./pages/project/projectPlan";

import EstimationCreate from "./pages/project/projectPlan/Estimations/EstimationCreate";
import EstimationEdit from "./pages/project/projectPlan/Estimations/EstimationEdit";

import UserList from "./pages/userList";
import Department from "./pages/department";
import Positions from "./pages/position";
import Worker from "./pages/worker";
import WorkerDetail from "./pages/worker/wokerDetail";
import WorkerCreate from "./pages/worker/workerCreate";
import WorkerEdit from "./pages/worker/workerEdit";

import ClientCompany from "./pages/clientCompany";
import ClientCompanyDetail from "./pages/clientCompany/detail";
import ClientCompanyCreate from "./pages/clientCompany/create/index";
import ClientCompanyEdit from "./pages/clientCompany/edit/index";

import CooperativeCompanyDetail from "pages/cooperativeCompany/detail";
import CooperativeCompanyCreate from "pages/cooperativeCompany/create/index";
import CooperativeCompanyEdit from "pages/cooperativeCompany/edit/index";

import CooperativeCompany from "./pages/cooperativeCompany";

import BillingCreate from "./pages/project/projectPlan/Billings/BillingCreate";
import Schedules from "./pages/schedules";
import SchedulesSP from "./pages/schedulesSP";
import SuggestionList from "./pages/suggestions";
import SuggestionControl from "./pages/suggestions/Suggestion";

import News from "pages/news";

import ChatRoom from "./pages/chats";
import ChatControl from "./pages/chats/ChatControl";
import Bill from "pages/bill";
import Estimate from "pages/estimate";
import { useEffect, useState } from "react";
import WebFont from "webfontloader";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RootState } from "./store/index";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import Setting from "pages/setting";
import SPHeader from "components/Header/sp-header";
import SPSidebar from "components/SideBar/sp-sidebar";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProjectDetailSP from "pages/project/projectDetailSP";
import ProjectPlanSP from "pages/project/projectPlan/projectPlanSP";
import WorkingLogSP from "pages/workingLogs";
import CreateWorkingLogSP from "pages/workingLogs/create";
import WeeklyReportSP from "pages/project/projectPlan/workingReportSP/WorkingReportList";
import WorkingReportCreateSP from "pages/project/projectPlan/workingReportSP/WorkingReportCreate";
import SyncfusionScheduler from "pages/syncfusion";
import Ads from "pages/ads";
import AdsCreate from "pages/ads/create";
import AdsDetail from "pages/ads/detail";
import AdsEdit from "pages/ads/edit";
import { isUserGuest } from "utils/helper/workerHelper";
import UserListSP from "pages/usersSP";

const PrivateRoute = ({ isLoggedIn, children }) => {
    if (!isLoggedIn) {
        window.location.href = "/login";
    }

    return children;
};

function App() {
    const { isLoggedIn } = useSelector((state: RootState) => state.auth);
    const [isChangeTab, setChangeTab] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const matchesSP = window?.innerWidth < 992;
    const { userData } = useSelector((state: RootState) => state.users);
    const checkUserGuest = isUserGuest(userData);
    useEffect(() => {
        WebFont.load({
            google: {
                families: ["Droid Sans", "Roboto"],
            },
        });
    }, []);
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    return (
        <Router>
            <div className={isLoggedIn ? "main d-flex flex-row" : "main"}>
                {isLoggedIn && (
                    <SideBar
                        setChangeTab={setChangeTab}
                        isChangeTab={isChangeTab}
                    />
                )}
                <SPSidebar
                    isOpenMenu={isOpenMenu}
                    setIsOpenMenu={setIsOpenMenu}
                />
                <div className="content-body d-flex flex-column">
                    {matchesSP ? (
                        <SPHeader
                            isOpenMenu={isOpenMenu}
                            setIsOpenMenu={setIsOpenMenu}
                        />
                    ) : (
                        <Header isChangeTab={isChangeTab}></Header>
                    )}

                    <Routes>
                        <Route
                            path="/"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    {matchesSP ? (
                                        checkUserGuest ? <SchedulesSP /> : <DashboardSP />
                                    ) : (
                                        checkUserGuest ? <Schedules /> :  <Dashboard />
                                    )}
                                </PrivateRoute>
                            }
                        />
                        {/* <Route path="/" element={<Dashboard />}/> */}
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/users"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <UserList />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/users"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <UserListSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/departments"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Department />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/positions"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Positions />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/bills"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Bill />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/estimates"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Estimate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/workers"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Worker />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/workers/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkerCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/workers/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkerEdit />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/workers/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkerDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectUpdate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Projects />
                                </PrivateRoute>
                            }
                        />
                        {/* <Route
                            path="/sp/projects"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectsSP />
                                </PrivateRoute>
                            }
                        /> */}
                        <Route
                            path="/sp/project/:id/chats"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatsSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/weekly-report"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WeeklyReportSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/weekly-report/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingReportCreateSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/weekly-report/:report_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingReportCreateSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/working_logs/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingLogSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/working_logs/create/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CreateWorkingLogSP />
                                </PrivateRoute>
                            }
                        />
                        {/* <Route
                            path="/sp/projects/:id/plans"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectPlanSP />
                                </PrivateRoute>
                            }
                        /> */}

                        <Route
                            path="/projects/detail/:id/plan"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingReport />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/:project_id/chats/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/:project_id/chats/:chat_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectDetailSP />
                                </PrivateRoute>
                            }
                        />

                        {/* estimation tab*/}
                        <Route
                            path="/projects/estimation/:id/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <EstimationCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/estimation/:id/edit/:estimationId"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <EstimationEdit />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/companies"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ClientCompany />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/companies/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ClientCompanyDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/companies/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ClientCompanyCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/companies/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ClientCompanyEdit />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/ads"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Ads />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/ads/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <AdsCreate />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/ads/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <AdsDetail />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/ads/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <AdsEdit />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/cooperative-companies"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CooperativeCompany />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/cooperative-companies/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CooperativeCompanyDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/cooperative-companies/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CooperativeCompanyCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/cooperative-companies/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CooperativeCompanyEdit />
                                </PrivateRoute>
                            }
                        />
                        {/* <Route
                            path="/projects/billing/:id/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <BillingCreate />
                                </PrivateRoute>
                            }
                        /> */}
                        <Route
                            path="/calendar"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Schedules />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/calendar"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SchedulesSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/suggestions"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SuggestionList />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/suggestions/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SuggestionControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/suggestion/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SuggestionControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/news"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <News />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/chats"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatRoom />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/chats/detail/:chat_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/chats/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/chats/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControlSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="sp/project/:id/chats/chat/:chat_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatBoxSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/setting"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Setting />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/syncfusion"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SyncfusionScheduler />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
