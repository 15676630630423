import styled from 'styled-components';
import vector from '../../../assets/icons/Vector.png';

const CreateScheduleWrapper = styled.div`
    width: 100%;
    font-family: 'Zen Kaku Gothic Antique';
    width: 430px;

    .description {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #1c1e21;
    }

    .filterBoxTop {
        padding: 5px 20px;

        .fieldLabel {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #1c1e21;
            display: block;
            margin: 0 0 4px;
        }

        .wrapSelectField {
            width: 100%;
        }

        .wrapTextField {
            .MuiInputBase-root {
                padding: 0;
            }

            textarea {
                background: #f3f3f3;
                border-radius: 4px;
                min-height: 80px;
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }

            .MuiOutlinedInput-root.MuiInputBase-formControl {
                input.MuiInputBase-input {
                    background: #f3f3f3;
                    border-radius: 4px;
                    border: 0;
                }
            }
        }
    }

    .inlineField {
        display: flex;
        .divider {
            padding: 6px 10px 0;
            margin: 0 0 12px;
            color: rgba(28, 30, 33, 0.5);
            font-size: 14px;
        }
    }

    .MuiDialogActions-root {
        padding: 5px 20px 30px !important;

        &.editingId {
            .customBtn {
                width: calc(33.3333% - 4px);
            }
        }

        .customBtn {
            width: calc(50% - 4px);
        }
    }

    .wrapColorBox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0 15px;

        button {
            padding: 0;
            border: 0;
            background: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            ~ button {
                margin: 0 0 0 8px;
            }

            &:hover,
            &.active {
                &:before {
                    opacity: 1;
                }
            }

            &:before {
                content: '';
                display: block;
                width: 6px;
                height: 10px;
                border: 2px solid #ffffff;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg);
                position: relative;
                top: -1px;
                opacity: 0;
                transition: all 0.3s ease 0s;
            }
        }
    }

    .MuiFormControlLabel-root {
        margin-left: -6px;
    }

    .MuiRadio-root {
        padding: 0 5px;

        &.Mui-checked {
            color: #11c442;
        }
    }

    .MuiTypography-root {
        color: #000000db;
        font-size: 14px;
    }

    .wrapDatepickerItem {
        input {
            transition: all 0.3s ease 0s;
        }
    }

    .hiddenValue {
        pointer-events: none;
        input {
            color: transparent !important;
        }

        .datePickerErrorText {
            display: none;
        }
    }

    /* Form Field */
    .wrapTextField {
        width: 100%;
        margin: 0 0 12px;

        .MuiFormControl-root {
            .MuiFormHelperText-root {
                margin-left: 0;
                color: #d32f2f !important;
            }

            .MuiOutlinedInput-root.MuiInputBase-formControl {
                input {
                    height: 32px;
                    background: #ffffff;
                    border-radius: 8px;
                    border: 1px solid #cdd1d5;
                    padding: 5px 15px;
                    color: #1c1e21;
                    font-weight: 400;
                    font-size: 14px;
                }
                textarea {
                    padding: 5px 15px;
                }
                fieldset {
                    border: none;
                }
            }
        }

        &.haveIcon {
            position: relative;
            img {
                width: 15px;
                position: absolute;
                z-index: 99;
                right: 10px;
                top: 9px;
                pointer-events: none;
            }
            .MuiOutlinedInput-root.MuiInputBase-formControl {
                input {
                    padding: 5px 30px 5px 10px;
                }
            }
        }
    }

    .wrapSelectField {
        margin: 0 0 15px;
        width: 300px;
        max-width: 100%;

        .MuiFormControl-root {
            .MuiFormHelperText-root {
                margin-left: 0;
                color: #d32f2f !important;
            }
        }

        &.multipleSelect {
            .MuiOutlinedInput-root.MuiInputBase-root {
                min-height: 40px;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 12px;
                    width: 13px;
                    height: 13px;
                    background: url('${vector}') no-repeat center;
                    transform: rotate(90deg);
                    background-size: contain;
                    pointer-events: none;
                }

                .MuiAutocomplete-tag {
                    height: 24px;
                }

                .MuiChip-root {
                    .MuiSvgIcon-root {
                        display: initial;
                        font-size: 16px;
                    }
                }
            }
        }

        .MuiSelect-select {
            font-weight: 400;
            color: #1c1e21;
            font-weight: 400;
            font-size: 14px;
        }

        .MuiOutlinedInput-root.MuiInputBase-root {
            min-height: 32px;
            background: #f3f3f3;
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 10px;
                top: 8px;
                width: 13px;
                height: 13px;
                background: url('${vector}') no-repeat center;
                transform: rotate(90deg);
                background-size: contain;
                pointer-events: none;
            }

            fieldset {
                border: none;
            }

            .MuiSvgIcon-root {
                display: none;
            }
        }
        .MuiAutocomplete-root {
            .MuiInputBase-root {
                padding: 5px 15px;
            }
        }
        .MuiAutocomplete-input {
            color: #1c1e21;
            font-weight: 400;
            font-size: 14px;
        }
        .MuiOutlinedInput-root {
            .MuiAutocomplete-input {
                padding: 0;
            }
        }
    }
`;

export default CreateScheduleWrapper;
