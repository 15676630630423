import styled from 'styled-components';

export const SchedulesWrapper = styled.div`
    width: 100%;
    background-color: #e3e6e8;
    .schedules-content {
        min-height: calc(100% - 56px);
        width: 100%;
        background: #ffffff;
        border-radius: 12px;
        font-family: 'Zen Kaku Gothic Antique';
    }

    .page-title {
        font-size: 24px;
        line-height: 32px;
        color: #1c1e21;
        font-weight: 700;
        font-family: 'Zen Kaku Gothic Antique';
    }

    .schedules-header {
        justify-content: space-between;
        align-items: center;
        padding: 20px 15px 12px 15px;
        font-family: 'Zen Kaku Gothic Antique';
    }

    .MuiToolbar-root  {
        padding-bottom: 30px !important;
    }

    .button-create {
        height: 36px;
    }
    .e-schedule .e-new-event {
        display: none;
    }
`;
