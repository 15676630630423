import styled from 'styled-components'

const StyledSPUserSearchArea = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;

  .search-fields-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 70%;

    .search-field-control {
      width: 40%;

      p {
        font-family: 'Zen Kaku Gothic Antique';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        margin: 0;
        padding-bottom: 4px;
      }

      input {
        padding: 0px 12px;
        width: 210px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #CDD1D5;
        border-radius: 8px;
      }
    }
  }

  .search-button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 30%;

    button {
      width: 150px;
      height: 36px;
      background: #215493;
      border-radius: 8px;
      font-family: 'Zen Kaku Gothic Antique';
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      border: none;
      margin-left: 20px;
    }
  }
`;

export default StyledSPUserSearchArea;