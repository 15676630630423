import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import createUserValidation from "utils/validation/registers/users/createUserValidation";
import userService from "services/userService";
import StyledSPUserForm from "../userFormStyle";
import { Autocomplete, Dialog, TextField } from "@mui/material";
import { alertError } from "utils/helper/appHelper";
import { roleOptions } from "pages/userList/components/create";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  // '& .MuiDialog-container': {
  //     marginBottom: '20px',
  // },
  "& .MuiDialog-paper": {
    overflowY: "visible",
  },
}));
const SPCreateNewUser = ({
  open,
  setOpen,
  onFinish,
  workerOptions,
  lineFriendsOptions,
}) => {
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    // reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const onCreate = async (input) => {
    input.worker_id = +input.worker.value;
    try {
      const data = await userService.createUser({
        ...input,
        line_bot_friend_id: input?.line_bot_friend_id?.value,
        role: input?.role?.value,
      });
      if (data) {
        onFinish();
      }
    } catch (error) {
      const errorResponse = error.response;
      alertError(errorResponse.data?.error);
      setOpen(false);
    }
  };

  return (
    <BootstrapDialog open={open} onClose={handleClose}>
      <StyledSPUserForm onSubmit={handleSubmit(onCreate)}>
        <h2 className="user-form-title">アカウント新規作成</h2>

        <div className="user-form-field">
          <p>email</p>
          <input
            type="email"
            autoComplete="off"
            // required
            {...register("email", createUserValidation(t).email())}
          ></input>
          {errors.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </div>

        <div className="user-form-field">
          <p>パスワード</p>
          <input
            type="password"
            autoComplete="off"
            // required
            {...register("password", createUserValidation(t).password())}
          ></input>
          {errors.password && (
            <span className="error">{errors.password.message}</span>
          )}
        </div>

        <div className="user-form-field">
          <p>パスワード再入力</p>
          <input
            type="password"
            autoComplete="off"
            // required
            {...register("password_confirmation", {
              required: true,
              validate: (val: string) => {
                if (watch("password") != val) {
                  return "このパスワードは合いません";
                }
              },
            })}
          ></input>
          {errors.password_confirmation && (
            <span className="error">
              {errors.password_confirmation.message}
            </span>
          )}
        </div>

        <div className="user-form-field">
          <p>社員</p>
          <Controller
            name="worker"
            control={control}
            render={({ field: { value } }) => (
              // <SelectSearch
              //     options={workerOptions}
              //     search
              //     filterOptions={handleFilter}
              //     {...register(
              //         'worker',
              //         createUserValidation(t).worker(),
              //     )}
              //     {...field}
              //     placeholder=""
              //     emptyMessage="該当なし"
              // />
              <Autocomplete
                disablePortal
                {...register("worker", createUserValidation(t).worker())}
                options={workerOptions.map((item) => ({
                  label: item.label,
                  value: String(item.value),
                }))}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  setValue("worker", newValue);
                }}
                classes={{
                  root: "autocomplete-select-style",
                }}
                value={{
                  label: value?.label || "",
                  value: value?.value || "",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    className="autocomplete-custom-textfield"
                  />
                )}
                noOptionsText="該当なし"
              />
            )}
          />
          {errors.worker && (
            <span className="error">{errors.worker.message}</span>
          )}
        </div>
        <div className="user-form-field">
          <p>友達リスト表示</p>
          <Controller
            name="line_bot_friend_id"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete
                sx={{
                  borderRadius: "8px",
                }}
                disablePortal
                options={lineFriendsOptions.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  setValue("line_bot_friend_id", newValue);
                }}
                classes={{
                  root: "autocomplete-select-style",
                }}
                value={{
                  label: value?.label || "",
                  value: value?.value || "",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    className="autocomplete-custom-textfield"
                  />
                )}
                noOptionsText="該当なし"
              />
            )}
          />
        </div>
        <div className="user-form-field">
          <p>権限</p>
          <Controller
            name="role"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete
                sx={{
                  borderRadius: "8px",
                }}
                disablePortal
                {...register("role", createUserValidation(t).role())}
                options={roleOptions.map((item) => ({
                  label: item.label,
                  value: String(item.value),
                }))}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  setValue("role", newValue);
                }}
                classes={{
                  root: "autocomplete-select-style",
                }}
                value={{
                  label: value?.label || "",
                  value: value?.value || "",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    className="autocomplete-custom-textfield"
                  />
                )}
                noOptionsText="該当なし"
              />
            )}
          />
          {errors.role && <span className="error">{errors.role.message}</span>}
        </div>
        <div className="user-form-button-group">
          <button className="return-button" type="button" onClick={handleClose}>
            戻る
          </button>
          <button className="register-button" type="submit">
            登録
          </button>
        </div>
      </StyledSPUserForm>
    </BootstrapDialog>
  );
};

export default SPCreateNewUser;
