import styled from "styled-components";

const StyledSPUserListTable = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 100px;

  .update-user-btn,
  .delete-user-btn {
    width: 72px;
    height: 32px;
    border: none;
    border-radius: 6px;
    font-family: "Zen Kaku Gothic Antique";
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 5px;
  }

  .update-user-btn {
    background: #215493;
    width: 60px;
  }

  .delete-user-btn {
    background: #ff5045;
    width: 60px;
  }
`;

export default StyledSPUserListTable;
