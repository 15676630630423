import styled from "styled-components";

const StyledSPUserForm = styled.form`
  width: 399px;
  min-height: 152px;
  background: #ffffff;
  border-radius: 12px;
  padding: 28px;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .user-form-title {
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: "Zen Kaku Gothic Antique";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #1c1e21;
  }

  .user-form-field {
    p {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      font-family: "Zen Kaku Gothic Antique";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      margin: 0;
      margin-bottom: 4px;
      margin-top: 12px;
    }

    .select-search {
      width: 100% !important;
      position: relative;
    }

    input {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 12px;
      gap: 8px;
      width: 100%;
      height: 36px;
      background: #ffffff;
      border: 1px solid #cdd1d5;
      border-radius: 8px;

      &:disabled {
        background-color: #eeeeee;
        opacity: 0.5;
      }
    }
  }

  .autocomplete-select-style {
    width: 100%;
    height: 100%;
    margin-right: 16px;
    padding: 0px;

    .MuiOutlinedInput-root {
      &.Mui-disabled {
        background-color: #eeeeee;
        opacity: 0.7;
      }
      height: 100%;
      padding: 0px;
      padding-left: 10px;

      fieldset {
        border-radius: 8px;
      }

      input {
        border: none;
      }
    }
  }
  // .MuiAutocomplete-popper {
  //     height: 220px;
  //     .MuiPaper-root {
  //         height: 100%;
  //     }
  // }

  .user-form-button-group {
    width: 100%;
    height: 20%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .return-button {
      width: 49%;
      height: 44px;
      border-radius: 8px;
      outline: none;
      border: 1px solid #215493;
      color: #215493;
    }

    .register-button {
      width: 49%;
      height: 44px;
      background: #215493;
      border-radius: 8px;
      outline: none;
      color: #ffffff;
      outline: none;
      border: none;
    }
  }
`;

export default StyledSPUserForm;
