import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import updateUserValidation from "utils/validation/registers/users/updateUserValidation";
import userService from "services/userService";

import StyledSPUserForm from "../userFormStyle";
import { Autocomplete, Dialog, TextField } from "@mui/material";
import { alertError } from "utils/helper/appHelper";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { isUserAdmin } from "utils/helper/workerHelper";
import { roleOptions } from "pages/userList/components/create";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    overflowY: "visible",
  },
}));

const SPUpdateUser = ({
  open,
  setOpen,
  selectedUser,
  onFinish,
  workerOptions,
  lineFriendsOptions,
}) => {
  const { t } = useTranslation();
  const findRole = roleOptions?.filter(
    (role) => role?.value === selectedUser?.role
  );
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: selectedUser.email,
      password: "",
      password_confirmation: "",
      worker: selectedUser.worker
        ? {
            label: selectedUser.worker.name,
            value: String(selectedUser.worker.id),
          }
        : null,
      line_bot_friend_id: selectedUser?.line_bot_friend
        ? {
            label: selectedUser?.line_bot_friend?.display_name,
            value: String(selectedUser?.line_bot_friend?.id),
          }
        : null,
      role: selectedUser.role
        ? {
            label: findRole[0]?.label,
            value: findRole[0]?.value,
          }
        : null,
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onUpdate = async (input) => {
    input.worker_id = +input.worker.value;
    try {
      const data = await userService.updateUser(
        {
          ...input,
          line_bot_friend_id: input?.line_bot_friend_id?.value || null,
          role: input?.role?.value,
        },
        selectedUser.id
      );
      if (data) {
        onFinish();
      }
    } catch (error) {
      const errorResponse = error.response;
      alertError(errorResponse.data?.error);
      setOpen(false);
    }
  };

  const { userData } = useSelector((state: RootState) => state.users);
  const isAdmin = isUserAdmin(userData);

  return (
    <BootstrapDialog open={open} onClose={handleClose}>
      <StyledSPUserForm onSubmit={handleSubmit(onUpdate)}>
        <h2 className="user-form-title">{selectedUser.email} 編集</h2>

        <div className="user-form-field">
          <p>email</p>
          <input
            type="email"
            autoComplete="off"
            // required
            disabled={!isAdmin}
            {...register("email", updateUserValidation(t).email())}
          />
          {errors.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </div>

        <div className="user-form-field">
          <p>パスワード</p>
          <input
            type="password"
            autoComplete="off"
            {...register("password", updateUserValidation(t).password())}
          ></input>
          {errors.password && (
            <span className="error">{errors.password.message}</span>
          )}
        </div>

        <div className="user-form-field">
          <p>パスワード再入力</p>
          <input
            type="password"
            autoComplete="off"
            {...register("password_confirmation", {
              validate: (val: string) => {
                if (watch("password") != val) {
                  return "このパスワードは合いません";
                }
              },
            })}
          ></input>
          {errors.password_confirmation && (
            <span className="error">
              {errors.password_confirmation.message}
            </span>
          )}
        </div>

        <div className="user-form-field">
          <p>社員</p>
          <Controller
            name="worker"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete
                disablePortal
                {...register("worker", updateUserValidation(t).worker())}
                options={workerOptions.map((item) => ({
                  label: item.label,
                  value: String(item.value),
                }))}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  console.log("change worker new value", newValue);
                  setValue("worker", newValue);
                }}
                classes={{
                  root: "autocomplete-select-style",
                }}
                value={{
                  label: value?.label || "",
                  value: value?.value || "",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    className="autocomplete-custom-textfield"
                  />
                )}
                noOptionsText="該当なし"
                disabled={!isAdmin}
              />
            )}
          />
          {errors.worker && (
            <span className="error">{errors.worker.message}</span>
          )}
        </div>
        <div className="user-form-field">
          <p>友達リスト表示</p>
          <Controller
            name="line_bot_friend_id"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete
                disablePortal
                sx={{
                  borderRadius: "8px",
                }}
                options={lineFriendsOptions.map((item) => ({
                  label: item.label,
                  value: String(item.value),
                }))}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  setValue("line_bot_friend_id", newValue);
                }}
                classes={{
                  root: "autocomplete-select-style",
                }}
                value={{
                  label: value?.label || "",
                  value: value?.value || "",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    className="autocomplete-custom-textfield"
                  />
                )}
                noOptionsText="該当なし"
                disabled={!isAdmin}
              />
            )}
          />
        </div>
        <div className="user-form-field">
          <p>権限</p>
          <Controller
            name="role"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete
                disablePortal
                sx={{
                  borderRadius: "8px",
                }}
                {...register("role", updateUserValidation(t).role())}
                options={roleOptions.map((item) => ({
                  label: item.label,
                  value: String(item.value),
                }))}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  setValue("role", newValue);
                }}
                classes={{
                  root: "autocomplete-select-style",
                }}
                value={{
                  label: value?.label || "",
                  value: value?.value || "",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    className="autocomplete-custom-textfield"
                  />
                )}
                noOptionsText="該当なし"
                disabled={!isAdmin}
              />
            )}
          />
          {errors.role && <span className="error">{errors.role.message}</span>}
        </div>
        <div className="user-form-button-group">
          <button className="return-button" type="button" onClick={handleClose}>
            戻る
          </button>
          <button
            className="register-button disabled:opacity-50"
            type="submit"
            disabled={
              !isAdmin && !watch("password") && !watch("password_confirmation")
            }
          >
            登録
          </button>
        </div>
      </StyledSPUserForm>
    </BootstrapDialog>
  );
};

export default SPUpdateUser;
