import { useFormik } from 'formik';
import { IWorkerIdentSortValue } from 'models';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import bookMarkService from 'services/bookMarkService';

export type IOptions = {
    id: any;
    name: string;
}[];

export type IWorkers = {
    id: number;
    name: string;
}[];
export type IDeparment2 = {
    id: number;
    name: string;
}
export interface IWorkersOptions {
    id: number;
    name: string;
    departments: number[] | null;
    position: number | null;
    departments2: IDeparment2[];
    identification: string | null;
};

export interface IBookMark {
    id: number;
    name: string;
    departments: any;
    positions: any;
    free_word: string;
    workers: IWorkers | [];
}

export type ReceivedProps = {
    positionOptions: IOptions;
    workerOptions: IWorkersOptions[];
    departmentOptions: IOptions;
    setSearchParam: Dispatch<SetStateAction<any>>;
    identSort: IWorkerIdentSortValue | null;
    setIdentSort: Dispatch<SetStateAction<IWorkerIdentSortValue | null>>;
};

const useFilterBox = (props: ReceivedProps) => {
    const [listBookMark, setListBookMark] = useState<IBookMark[]>();
    const [bookMarkDetail, setBookMarkDetail] = useState<IBookMark>();

    const fetchBookMarks = async () => {
        try {
            const { book_marks } = await bookMarkService.getListBookMark();
            setListBookMark(book_marks);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteBookMark = async (id) => {
        try {
            await bookMarkService.deleteBookMark(id);
            fetchBookMarks();
        } catch (error) {
            console.log(error);
        }
    }

    const createBookMark = async () => {
        const payload = {
            attribute_worker_ids: formik?.values?.attribute_worker_ids?.map((v: { id: any; }) => v?.id),
            name: formik?.values?.name,
            attribute_position_ids: formik?.values?.attribute_position_ids?.map((v: { id: any; }) => v?.id),
            attribute_department_ids: formik?.values?.attribute_department_ids?.map((v: { id: number; }) => v?.id),
            free_word: formik?.values?.free_word,
        }
        try {
            const data = await bookMarkService.createBookMark(payload);
            if (data) {
                formik.resetForm();
                fetchBookMarks();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getListWorkers = (departmentIds: number[], positionIds: number[], workerIds: number[]) => {
        const listId: number[] = [];
        props.workerOptions.map((item: IWorkersOptions) => {
            let check = true;
            item?.departments && item?.departments?.length > 0 && item?.departments.map((departmentId: number) => {
                departmentIds?.length > 0 && departmentIds.map((id: number) => {
                    if (id === departmentId) {
                        check = false;
                        !listId.includes(item.id) && listId.push(item.id);
                    }
                })
            })

            check && item?.position && positionIds?.length > 0 && positionIds.map((id: number) => {
                if (id === item?.position) {
                    check = true;
                    !listId.includes(item.id) && listId.push(item.id);
                }
            })

            check && workerIds?.length > 0 && workerIds.map((workerId: number) => {
                !listId.includes(workerId) && listId.push(workerId);
            })
        })
        return listId
    }

    const filterBookMarks = (idBookMark: number) => {
        let newArrBookMarks = listBookMark?.filter((bookMark: { id: number; }) => bookMark?.id === idBookMark);
        setBookMarkDetail(newArrBookMarks?.[0]);
        const listWorkers = getListWorkers(newArrBookMarks?.[0]?.departments?.map(v => v.id), newArrBookMarks?.[0]?.positions?.map(v => v.id), newArrBookMarks?.[0]?.workers?.map(v => v.id) || [])
        if (newArrBookMarks?.[0]) {
            formik.setValues({
                name: newArrBookMarks[0]?.name,
                attribute_department_ids: newArrBookMarks[0]?.departments,
                attribute_position_ids: newArrBookMarks[0]?.positions,
                attribute_worker_ids: newArrBookMarks[0]?.workers || [],
                free_word: newArrBookMarks[0]?.free_word,
            });
            props.setSearchParam({
                attribute_department_ids: newArrBookMarks[0]?.departments?.map(v => v.id),
                attribute_position_ids: newArrBookMarks[0]?.positions?.map(v => v.id),
                attribute_worker_ids: newArrBookMarks[0]?.workers?.map(v => v.id) || [],
                free_word: newArrBookMarks[0]?.free_word,
                worker_list: listWorkers
            });
        }
    }

    useEffect(() => {
        fetchBookMarks();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            attribute_department_ids: [],
            attribute_position_ids: [],
            attribute_worker_ids: <IWorkers>[],
            free_word: '',
            name: '',
        },
        onSubmit: (values: any) => {
            const listWorkers = getListWorkers(values?.attribute_department_ids?.map(v => v.id), values?.attribute_position_ids?.map(v => v.id), values?.attribute_worker_ids?.map(v => v.id))
            props.setSearchParam({
                ...values,
                attribute_department_ids: values?.attribute_department_ids?.map(v => v.id),
                attribute_position_ids: values?.attribute_position_ids?.map(v => v.id),
                attribute_worker_ids: values?.attribute_worker_ids?.map(v => v.id),
                worker_list: listWorkers
            });
        },
    });

    return {
        ...props,
        listBookMark,
        deleteBookMark,
        createBookMark,
        filterBookMarks,
        bookMarkDetail,
        formik,
    };
};

export type Props = ReturnType<typeof useFilterBox>;

export default useFilterBox;
