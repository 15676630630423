import { FC, useState } from 'react';

import SchedulerBox from './SchedulerBox';

import plus from '../../assets/icons/Plus.png';
import TabBox from './TabBox';
import useSchedules, { Props, ReceivedProps } from './hook';
import { SchedulesWrapper } from './style';
import FilterBox from './FilterBox';
import { useNavigate } from 'react-router-dom';
// import { GoogleLogin } from '@react-oauth/google';
// import GoogleIcon from '@mui/icons-material/Google';
import { FcGoogle } from 'react-icons/fc';
import { Box } from '@mui/material';
import { isUserGuest } from "utils/helper/workerHelper";
import { useSelector } from "react-redux";
import { RootState } from 'store';

const SchedulesLayout: FC<Props> = ({
    openDeleteConfirmationDialog,
    openScheduleFormDialog,
    openPlanUpdateModal,
    activeTab,
    workerOptions,
    positionOptions,
    departmentOptions,
    searchParam,
    setSearchParam,
    setActiveTab,
    setOpenScheduleFormDialog,
    setOpenDeleteConfirmationDialog,
    setOpenPlanUpdateModal,
    onGoogleAuthenticate,
    hasCalendarPermission,
    identSort,
    setIdentSort,
}) => {
    const navigate = useNavigate();
    const { userData } = useSelector((state: RootState) => state.users);
    let checkUserGuest = isUserGuest(userData);
    const [isShowDefaultDate, setIsShowDefaultDate] = useState(true);
    // const responseMessage = (response) => {
    //     console.log(response);
    // };
    // const errorMessage = () => {
    //     // console.log(error);
    // };

    return (
        <>
            <SchedulesWrapper className="container-schedules d-flex flex-column">
                <div className="schedules-content d-flex flex-column">
                    <div className="schedules-header d-flex flex-row">
                        <div className="page-title">カレンダー</div>
                        {!checkUserGuest && 
                        <button
                            className="button-create"
                            onClick={() => {
                                setOpenScheduleFormDialog(true);
                                navigate(`/calendar`, {
                                    state: {
                                        editingId: null,
                                    },
                                });
                                setIsShowDefaultDate(false);
                            }}
                        >
                            <img width={'16px'} height={'16px'} src={plus} />
                            <span>新規作成</span>
                        </button>
                        }
                    </div>

                    <TabBox activeTab={activeTab} setActiveTab={setActiveTab} />

                    {/* <GoogleLogin 
                        onSuccess={responseMessage} 
                        onError={errorMessage} 
                        text="continue_with"
                        size="medium"
                    /> */}
                    {
                        !hasCalendarPermission && !checkUserGuest ? (
                            <div>
                                <button className='button-create' onClick={onGoogleAuthenticate}>
                                    <FcGoogle />
                                    Google
                                </button>
                            </div>
                        ) : <Box height={activeTab === 0 ? "36px" : 0}/>
                    }

                    {activeTab !== 0 && (
                        <FilterBox
                            workerOptions={workerOptions || []}
                            positionOptions={positionOptions || []}
                            departmentOptions={departmentOptions || []}
                            setSearchParam={setSearchParam}
                            identSort={identSort}
                            setIdentSort={setIdentSort}
                        />
                    )}

                    <SchedulerBox
                        activeTab={activeTab}
                        searchParam={searchParam}
                        workerOptions={workerOptions || []}
                        setOpenScheduleFormDialog={setOpenScheduleFormDialog}
                        setOpenDeleteConfirmationDialog={
                            setOpenDeleteConfirmationDialog
                        }
                        setOpenPlanUpdateModal={setOpenPlanUpdateModal}
                        openDeleteConfirmationDialog={
                            openDeleteConfirmationDialog
                        }
                        openScheduleFormDialog={openScheduleFormDialog}
                        openPlanUpdateModal={openPlanUpdateModal}
                        isShowDefaultDate={isShowDefaultDate}
                        setIsShowDefaultDate={setIsShowDefaultDate}
                        identSort={identSort}
                        setIdentSort={setIdentSort}
                    />
                </div>
            </SchedulesWrapper>
        </>
    );
};

const Schedules: FC<ReceivedProps> = (props) => (
    <SchedulesLayout {...useSchedules(props)} />
);

export default Schedules;
