import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import TopPageService from 'services/topPageServiceSP';
import ProgressBar from './components/Progressbar';
import moment from 'moment';
import NewsService from 'services/newsService';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import adsService from 'services/adsApi';
import SlideAds from './components/SlideAds';
import Modal from 'react-modal';
import NewsModal from './components/newsModal';
import scheduleService from 'services/scheduleService';
import './dashboard.css';
import { formatDateTime } from 'utils/helper/dateHelper';
import { Calendar } from 'assets/menuIcons';
import { useNavigate } from 'react-router-dom';

const NewsItem = ({ newItem }) => {
    const [isShowMore, setIsShowMore] = useState(false);
    return (
        <Box marginBottom="12px">
            <Typography
                lineHeight="20px"
                color="#1C1E21"
                marginBottom="16px"
                fontWeight={500}
            >
                {moment(newItem?.publish_at).format('YYYY年M月D日')}
            </Typography>
            <Typography
                fontSize={14}
                fontWeight={700}
                lineHeight="24px"
                color="#448CDF"
                marginBottom="8px"
            >
                {newItem?.title}
            </Typography>
            <Typography
                sx={
                    !isShowMore
                        ? {
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                          }
                        : {}
                }
                fontSize={14}
                fontWeight={400}
                lineHeight="24px"
                color="#rgba(0, 0, 0, 0.7)"
            >
                {newItem?.content}
            </Typography>
            {newItem?.content.length > 50 && (
                <Typography
                    onClick={() => setIsShowMore(!isShowMore)}
                    sx={{ textDecoration: 'underline' }}
                    color="#195192"
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="24px"
                >
                    もっと見る
                </Typography>
            )}
        </Box>
    );
};

const CardWrapper = ({ children, title, classNameCustom = '' }) => {
    return (
        <Box marginBottom="20px">
            <Typography lineHeight="30px" color="#1C1E21" fontWeight={700}>
                {title}
            </Typography>
            <Box
                marginTop="16px"
                border="1px solid #CDD1D5"
                borderRadius="12px"
                padding="16px"
                className={classNameCustom}
            >
                {children}
            </Box>
        </Box>
    );
};

type WorkerAttribute = {
    id: number;
    name: string;
    worker_id: number;
}
interface ISchedule {
    id: number;
    title: string;
    content: string;
    start_date: Date;
    end_date: Date;
    worker_schedule_attributes: WorkerAttribute[];
    idNews: number;
    created_by: {id: number, name: string};
    is_all_day: boolean;
}
interface ISlider {
    name: string;
    file_path: string;
    content_type: string;
    start_date: Date;
    message?: string;
}
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      backgroundColor: "#FFFFFF",
      position: "fixed"
    },
  };
const DashboardSP = () => {
    const [news, setNews] = useState<any>([]);
    const [progress, setProgress] = useState<any>(null);
    const [request, setRequest] = useState<any>(null);
    const [isShowMoreNews, setIsShowMoreNews] = useState(false);
    const [listNewsSchedules, setListNewsSchedules] = useState<ISchedule[]>([]);
    const [scheduleDetail, setScheduleDetail] = useState<ISchedule>();
    const [openScheduleModal, setOpenScheduleModal] = useState(false);
    const [isAgree, setIsAgree] = useState(false);
    const [isRefuse, setIsRefuse] = useState(false);
    const [typeSubmit, setTypeSubmit] = useState('');
    const [openPopupAgainFirst, setOpenPopupAgainFirst] = useState(false);
    const [adsManagements, setAdsManagements] = useState<ISlider[]>([]);
    const [nameWorkerCreate, setNameWorkerCreate] = useState('');

    const { userData } = useSelector((state: RootState) => state.users);
    const today = formatDateTime(new Date(), 'yyyy-MM-DD');
    const navigate = useNavigate();
   

    const getTopPageProfiles = async () => {
        try {
            const response: any = await TopPageService.getTopPageNews();
            //setNews(response?.top_page);
        } catch (error) { }
    };

    const getTopPageProgress = async () => {
        try {
            const response: any = await TopPageService.getTopPageProgress();
            const listProgress = [
                {
                    value: response?.negotiating_project * 100 + '%',
                    title: '見込み案件',
                    amount: response?.negotiating_project,
                },
                {
                    value: response?.inprogress_project * 100 + '%',
                    title: '実施中案件数',
                    amount: response?.inprogress_project,
                },
                {
                    value: response?.complete_project * 100 + '%',
                    title: '完了案件',
                    amount: response?.complete_project,
                },
            ];
            setProgress(listProgress);
        } catch (error) {}
    };

    const getTopPageRequest = async () => {
        try {
            const response: any = await TopPageService.getTopPageRequest();
            setRequest(response?.top_page);
        } catch (error) { }
    };

    const getListNews = async () => {
        try {
          // const response = await topPageService.getListNews()
          const response = await NewsService.getListNews({ page: 1, per_page: 0, include_schedule: true });
          if (response !== null && response !== undefined) {
              const todayNews = response.news.filter((item) => item.publish_at === today && !item.schedule);
              const otherDayNews = response.news.filter((item) => item.publish_at !== today && !item.schedule);
              setNews([...todayNews, ...otherDayNews]);

            const schedules = response.news.map((item: { schedule: ISchedule; id: number; }) => {
              return {...item.schedule, idNews: item.id}
            });
            let listFilterNewsSchedules = schedules?.filter((it: ISchedule) => typeof (it.id) === 'number' && new Date(it.end_date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
            let listFilterScheduleMarkerAgree = listFilterNewsSchedules?.filter((item: { schedule_maker_is_agree: boolean | null | string; created_by: { id: number; }; worker: { id: number; }; }) => !(item?.schedule_maker_is_agree && item?.created_by?.id === item?.worker?.id && item?.created_by?.id === userData?.worker?.id))
            setListNewsSchedules(listFilterScheduleMarkerAgree);
          }
        } catch (err) {
          console.log('err', err)
        }
      }

    const getNewsDetail = async(id: number) => {
        try {
          const res = await NewsService.getNews(id);
          setScheduleDetail(res?.new?.schedule);
          setOpenScheduleModal(true);
        } catch (error) {
          console.log(error)
        }
    }
    const getListAdsManagements = async () => {
        try {
          const response = await adsService.getAdsManagements({ page: 1, per_page: 0 });
          if (response !== null && response !== undefined) {
            let listSliders = response?.ads_managements;
            let newSliders:ISlider[] = [];
            for(let i = 0; i < listSliders?.length; i++) {
              const arrFiles = listSliders[i]?.files;
              if(arrFiles !== null) {
                for(let j = 0; j < arrFiles.length; j++) {
                  newSliders.push({name: listSliders[i]?.name, file_path: arrFiles[j].file_path, content_type: arrFiles[j]?.content_type, start_date: listSliders[i]?.start_date, message: listSliders[i]?.message})
                }
              }
            }
            let newSlidersNotFuture = newSliders?.filter(slide => new Date(slide?.start_date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0));
            let maxItem = newSlidersNotFuture?.sort((a,b) => {
              return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
            })[0];
            setAdsManagements(newSlidersNotFuture?.filter(slide => new Date(slide?.start_date).setHours(0, 0, 0, 0) === new Date(maxItem?.start_date).setHours(0, 0, 0, 0)));
          }
        } catch (err) {
          console.log('err', err)
        }
    }
    const updateScheduleStatus = async (isAgree: boolean) => {
        if (!scheduleDetail || !userData.worker) return;
        const params = {
          worker_id: userData?.worker?.id,
          is_agree: isAgree,
        }
        try {
          await scheduleService.updateScheduleStatus(scheduleDetail?.id, params);
        } catch (error) {
          console.log(error);
        }
    }

    const convertDateToJapan = (dateTime, isAllDay = true) => {
        let newDateTime = dateTime.replace(/ /g, "T")
        const date = new Date(newDateTime)
        var lang = 'en';
        let year = date.toLocaleString(lang, { year: 'numeric' });
        let month = date.getMonth() + 1;
        let day = date.toLocaleString(lang, { day: 'numeric' });
        let hour = date.getHours();
        let minute = date.getMinutes();
        const textDate = !isAllDay ? `${year}年${month}月${day}日 ${hour < 10 ? `${hour}0` : hour}:${minute < 10 ? `${minute}0` : minute}` : `${year}年${month}月${day}日`;
        return textDate
    }

    const handleClosePopupShedule = () => {
        setOpenScheduleModal(false);
        setIsRefuse(false);
        setIsAgree(false);
    }

    const handleAgreeSchedule = () => {
        setOpenPopupAgainFirst(true);
        setTypeSubmit('agree');
    }

    const handleRefuseSchedule = () => {
        setOpenPopupAgainFirst(true);
        setTypeSubmit('refuse');
    }

    const handleClosePupupAgain = () => {
        setOpenPopupAgainFirst(false);
        setOpenScheduleModal(false);
        setTypeSubmit('');
    }
      
    const handleSubmitAgain = () => {
        typeSubmit === 'agree' ? updateScheduleStatus(true) : updateScheduleStatus(false);
        setOpenScheduleModal(false);
        setOpenPopupAgainFirst(false);
    }
    const handleAgreeSendApiFirst = () => {
        updateScheduleStatus(true);
        setOpenScheduleModal(false);
    }
    const handleRefuseSendApiFirst = () => {
        updateScheduleStatus(false);
        setOpenScheduleModal(false);
    }

    useEffect(() => {
        getTopPageProfiles();
        getTopPageProgress();
        getTopPageRequest();
        getListNews();
        getListAdsManagements();
    }, []);

    return (
        <Box
            sx={{
                '.MuiTypography-root, button, th, td': {
                    fontFamily: 'Zen Kaku Gothic Antique',
                },
            }}
            padding="25px 16px 16px 16px"
        >
            <div className='flex justify-end'>
                <button className="button-create !w-[150px]" onClick={() => navigate('/sp/calendar')}>
                    <Calendar strokeColor='#215493' />
                    カレンダー
                </button>
            </div>
            <CardWrapper classNameCustom='dashboard-news-content1' title="お知らせ">
                {news?.map((newItem, index) =>
                    isShowMoreNews ? (
                        <NewsItem key={newItem?.id} newItem={newItem} />
                    ) : (
                        index < 2 && (
                            <NewsItem key={newItem?.id} newItem={newItem} />
                        )
                    ),
                )}
                {listNewsSchedules?.map((schedule, i) => {
                  return (
                    <div className='dashboard-news-item d-flex flex-row' key={i} style={{margin: 0, paddingBottom: "12px"}}>
                      <div>
                          <p className='news-date' style={{ marginRight: '10px', marginBottom: '10px' }}>{convertDateToJapan(schedule?.start_date)}</p>

                        <div>
                          <p className='news-inf' style={{}}>{schedule?.title}</p>
                          <div className='news-inf-content-ctn d-flex flex-row' style={{alignItems: "flex-end", justifyContent: "space-between"}}>
                            <p className='news-inf-content news-inf-content2'>{ schedule?.created_by?.name || "admin" } から{convertDateToJapan(schedule?.start_date, schedule?.is_all_day)} - {convertDateToJapan(schedule?.end_date, schedule?.is_all_day)}に{schedule?.title} {schedule?.content}の参加依頼がありました。</p> 
                            <div className='news-inf-content-show-more' onClick={() => {
                              getNewsDetail(schedule?.idNews);
                              setNameWorkerCreate(schedule?.created_by?.name || "admin")
                            }}>
                              もっと見る
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
                
            </CardWrapper>
            <CardWrapper classNameCustom='' title="今月進捗">
                <Box
                    sx={{
                        '.highcharts-yaxis-labels, .highcharts-title, .highcharts-axis-title, .highcharts-legend-item':
                            {
                                display: 'none',
                            },
                        '.progress-chart-item': {
                            paddingLeft: '0px !important',
                        },
                        '.title-progress': {
                            width: '115px',
                        },
                    }}
                >
                    {progress?.map((item) => (
                        <Box
                            sx={{
                                marginBottom: '8px',
                                '&:last-child': {
                                    marginBottom: '0px',
                                },
                            }}
                            className="progress-chart-item d-flex flex-row"
                        >
                            <Typography className="title-progress">
                                {item.title}
                            </Typography>
                            <Box className="custom-progress d-flex flex-row">
                                <ProgressBar width={item.value}></ProgressBar>
                                <label>{item?.amount || 0}</label>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </CardWrapper>
            <Modal
              contentLabel="schedule modal"
              isOpen={openScheduleModal}
              onRequestClose={handleClosePopupShedule}
              style={customStyles}
            >
              <NewsModal
                type='schedule'
                close={handleClosePopupShedule}
                news={scheduleDetail}
                convertDateToJapan={convertDateToJapan}
                handleAgree={handleAgreeSchedule}
                handleRefuse={handleRefuseSchedule}
                userData={userData}
                classNameCustom="!w-[300px]"
                handleAgreeSendApiFirst={handleAgreeSendApiFirst}
                handleRefuseSendApiFirst={handleRefuseSendApiFirst}
                nameWorkerCreate={nameWorkerCreate}
              />
            </Modal>
            <Modal
              contentLabel="schedule modal small first"
              isOpen={openPopupAgainFirst}
              onRequestClose={() => setOpenPopupAgainFirst(false)}
              style={customStyles}
            >
              <div className='mb-[12px]'>{typeSubmit === "agree" ? "本当に承認しますか？": "本当に却下しますか？"}</div>
              <div className='flex'>
                <button className='btnCancel' onClick={handleClosePupupAgain}>キャンセル</button>
                <button className='btnOK' onClick={handleSubmitAgain}>はい</button>
              </div>
            </Modal>
            {
                adsManagements?.length > 0 && <SlideAds classNameCustom='custom-img' listSliders={adsManagements} />
            }  
        </Box>
    );
};
export default DashboardSP;
